.welcome{
	position: relative;
	height:100vh;
	width:100%;
	top:0;
	left:0;
	right:0;
	overflow: hidden;
}

.welcome .logo{
	text-align: center;
	margin: 20px 0px;
}

.welcome .heading{
	position: absolute;
	height:auto;
	width:100%;
	top:50%;
	transform: translateY(-50%);
	text-align: center;
	text-shadow:0px 0.5px 5px rgba(0, 0, 0, 0.2);
}

.welcome .heading .head{
	position: relative;
	font-family: 'RacingSansOne',sans-serif;
	font-size:48px;
	font-weight: 600; 
	color: #FFFFFF;
}

.welcome .heading .head span{
	color: #2b6218;
}

.welcome .heading .sub{
	position: relative;
	font-family: 'cinzel',sans-serif;
	font-size:22px;
	font-weight: 300; 
	color: #FFFFFF;
}

h5.dashboard{
	padding: 10px;
	border-radius: 4px;
	border-color: #11cb5f;
	border-width: 1px;
	border-style: solid;
	background-color: white;
	
}

@media (max-width: 800px) {
  .custom-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}