/* Used by Index.js, App.js, ControlPanel.js */
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* Used by input type date to not overwrite floating label and placeholder on empy fields */
input[value=""]::-webkit-datetime-edit{ color: transparent; }
input:focus::-webkit-datetime-edit{ color: rgba(0, 0, 0, 0.5); }
input[value=""].Mui-disabled {
    -webkit-text-fill-color: transparent !important;
}	

/* Form data rows/cels/controls format */
.row.styled {
	margin-bottom: 5px;
	padding-top: 20px;
}

div.styled  {
	margin-bottom: 8px;
}

.grid-disabled {
	pointer-events: none;
}

h1.formSection {
	font-size: 1.7em;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.2);
	padding: 3px;
	font-weight: 500;
}

h2.formSection {
	font-size: 1.2em;
	text-align: left;
	background-color: rgba(0, 0, 0, 0.2);
	padding: 3px;
	font-weight: 500;
}

.subtitle {
	font-weight: bold;
	color: rgba(0, 0, 0, 0.6);
}

.signature_canvas canvas {
    border: 1px dashed gray;
}
